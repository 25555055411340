<template>
  <v-container
    pa-0
    fluid
    fill-height
    :style="
      dark
        ? 'width: 100%;background: linear-gradient(180deg, rgba(15,15,15,1) 0%, rgba(36,36,36,1) 100%);'
        : 'width: 100%;background: linear-gradient(180deg, rgba(183,227,228,1) 0%, rgba(180,180,180,1) 100%);'
    "
  >
    <v-slide-y-transition mode="out-in">
      <v-layout column align-center pa-3>
        <v-layout style="width: 100%">
          <v-btn to="/" style="margin-left: -5px" icon :dark="dark">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-layout>

        <v-layout
          column
          align-center
          style="width: 100%; margin-top: -25px"
          pb-4
        >
          <v-img :src="logoSrcDark" max-width="150" max-height="33"></v-img>
        </v-layout>

        <v-card
          class="pa-2"
          style="height: 100%; width: 100%; max-width: 400px"
          :dark="dark"
        >
          <v-layout column align-center fluid fill-height v-if="!resetEmaiSent">
            <p class="mt-0"></p>
            <v-form v-model="valid" ref="form" dense>
              <v-flex style="margin: 0 16px 0 16px; max-width: 400px">
                <v-layout column align-center v-if="!changePhoneNumber">
                  <span
                    style="font-size: 18px"
                    class="font-weight-bold"
                    v-if="!forgotPw"
                    >{{ lang[getLanguage].SIGN_IN_HERE }}</span
                  >
                  <span
                    style="font-size: 18px"
                    class="font-weight-bold"
                    v-if="forgotPw"
                    >{{ lang[getLanguage].PW_RECOVERY }}</span
                  >
                  <span class="subheading font-weight-medium" v-if="forgotPw">{{
                    lang[getLanguage].ENTER_EMAIL
                  }}</span>
                </v-layout>
                <v-layout column align-center v-else-if="changePhoneNumber">
                  <span style="font-size: 18px" class="font-weight-bold">{{
                    lang[getLanguage].CHANGE_PHONE_NR
                  }}</span>
                  <span class="subheading font-weight-medium">{{
                    lang[getLanguage].SIGN_IN_FIRST
                  }}</span>
                </v-layout>
                <p class="mt-2"></p>
                <!-- <PhoneInput :phone="phone"/> -->
                <v-text-field
                  style="width: 100%"
                  v-model="email"
                  outlined
                  color="lime darken-4"
                  type="email"
                  :rules="emailRules"
                  label="Email"
                  append-icon="mdi-email-outline"
                  required
                  @input="change1"
                ></v-text-field>
                <v-text-field
                  style="width: 100%"
                  v-model="password"
                  v-if="!forgotPw"
                  :type="show1 ? 'text' : 'password'"
                  outlined
                  color="lime darken-4"
                  :rules="passwordRules"
                  min="8"
                  label="Password"
                  :append-icon="
                    show1 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                  "
                  @click:append="show1 = !show1"
                  v-on:keyup="enterPressed"
                  @input="change1"
                ></v-text-field>

                <v-btn
                  text
                  :dark="dark"
                  :color="
                    dark ? 'deep-purple darken-1' : 'deep-purple lighten-3'
                  "
                  v-show="!alert"
                  @click="(forgotPw = true), (changePhoneNumber = false)"
                  v-if="!forgotPw"
                  class="font-weight-bold"
                  style="text-transform: none !important"
                  >{{ lang[getLanguage].FORGOT_PWD }}</v-btn
                >
                <v-btn
                  text
                  :dark="dark"
                  :color="
                    dark ? 'deep-purple darken-1' : 'deep-purple lighten-3'
                  "
                  to="/signup"
                  v-show="!alert"
                  v-if="!forgotPw"
                  style="text-transform: none !important"
                >
                  <span class="font-weight-bold"
                    >{{ lang[getLanguage].NO_ACCOUNT }}<br /><span
                      style="padding-top: 5px"
                      class="font-weight-regular"
                      >{{ lang[getLanguage].SIGN_UP_NOW }}</span
                    ></span
                  >
                </v-btn>

                <span class="subheading" v-if="forgotPw">{{
                  lang[getLanguage].SEND_RESET_LINK
                }}</span>

                <p v-if="alert" class="mt-2"></p>
                <v-alert
                  dense
                  color="pink"
                  type="error"
                  :value="alert"
                  outlined
                  border="left"
                >
                  <span>{{ alertMessage }}</span>
                </v-alert>
              </v-flex>
            </v-form>

            <v-layout fill-height></v-layout>

            <v-btn
              :dark="dark"
              :color="dark ? 'deep-purple darken-1' : 'deep-purple lighten-3'"
              large
              style="width: 100%"
              v-if="!forgotPw"
              v-on:click="signIn"
              :loading="loading"
              :disabled="loading || email.length === 0 || password.length <= 6"
            >
              <v-icon left>mdi-arrow-right-bold</v-icon>
              {{ lang[getLanguage].BUT_SIGN_IN }}
            </v-btn>
            <v-btn
              :dark="dark"
              :color="dark ? 'deep-purple darken-1' : 'deep-purple lighten-3'"
              large
              style="width: 100%"
              v-if="forgotPw"
              v-on:click="resetPwdLink"
              :loading="loading"
              :disabled="loading || email.length === 0 || !valid"
            >
              <v-icon left>mdi-lock-reset</v-icon>
              {{ lang[getLanguage].BUT_RESET_PW }}
            </v-btn>
            <p class="mt-0" v-if="forgotPw"></p>
            <v-btn
              color="grey darken-1"
              outlined
              large
              style="width: 100%; color: #fff"
              v-if="forgotPw"
              v-on:click="(forgotPw = false), (alert = false)"
            >
              <v-icon left>mdi-cancel</v-icon>
              {{ lang[getLanguage].BUT_CANCEL }}
            </v-btn>
          </v-layout>
          <v-layout
            column
            align-center
            fluid
            fill-height
            v-else-if="resetEmaiSent"
            pa-2
          >
            <p class="mt-0"></p>
            <span style="font-size: 18px" class="font-weight-bold">{{
              lang[getLanguage].EMAIL_SENT
            }}</span>
            <p class="mt-0"></p>
            <span class="subheading font-weight-medium">{{
              lang[getLanguage].CHECK_EMAIL
            }}</span>
            <p class="mt-0"></p>
            <span class="subheading">{{
              lang[getLanguage].EMAIL_INSTRUCTIONS
            }}</span>
            <p class="mt-0"></p>
            <v-btn
              text
              large
              style="color: #00838f; text-transform: none !important"
              @click="mailToOB"
              >yo@charged.asia</v-btn
            >
            <v-layout fill-height></v-layout>

            <v-btn
              color="#388E3C"
              large
              style="width: 100%; color: #fff"
              v-on:click="(resetEmaiSent = false), (forgotPw = false)"
            >
              <v-icon left>mdi-arrow-left-bold</v-icon>
              {{ lang[getLanguage].BUT_BACK_TO_SIGN_IN }}
            </v-btn>
          </v-layout>
        </v-card>
        <p class="mt-3"></p>
      </v-layout>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import store from "@/store/index";
import { auth } from "@/main";
export default {
  /* components: {
    PhoneInput
  }, */
  name: "login",
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    drawer: Boolean,
    isMobileDevice: Boolean,
  },
  data() {
    return {
      lang: this.$store.state.lang,
      phone: {
        code: "+1",
        number: "",
      },
      loader: null,
      loading: false,
      alert: false,
      valid: false,
      alertMessage: "",
      email: "",
      resetEmaiSent: false,
      emailRules: [
        (v) => !!v || "Email is required",
        (v) =>
          /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(v) ||
          "Email address must be valid",
      ],
      password: "",
      passwordRules: [
        (v) => !!v || "Password is required",
        // v => (v && v.length >= 7) || 'Minimal 7 characters'
      ],
      forgotPw: false,
      changePhoneNumber: false,
      show1: false,
      logoSrcDark: "",
    };
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language === null
        ? "id"
        : this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
  },
  mounted() {
    this.currentUser = auth.currentUser;
    if (this.currentUser && this.getUser.accType > 9) {
      console.log("####### Welcome App User #######");
      this.$router.push("/");
      return;
    }
  },
  created() {
    // this.events = WialonGetters.sess.Request._message
    store.commit("HideNav");
    this.logoSrcDark = "./img/logo/charged_logos.png";
    console.log(this.$route.params.type);
    this.changePhoneNumber = this.$route.params.type === "newnr";
  },
  methods: {
    signIn: function () {
      this.loader = "loading";
      let vm = this;
      auth.signInWithEmailAndPassword(this.email, this.password).then(
        (user) => {
          // Check if Account
          console.log("user");
          console.log(user);
          if (this.changePhoneNumber) {
            vm.$router.push("/changenr");
          } else {
            vm.$router.push("/signupnext");
          }
        },
        (error) => {
          // alert('Oops. ' + err.message)
          this.alert = true;
          this.alertMessage = error.message;
          this.loader = null;
          this.loading = false;
        }
      );
    },
    resetPwdLink() {
      // this.loader = 'loading'
      auth
        .sendPasswordResetEmail(this.email)
        .then(() => {
          // Email sent.
          this.resetEmaiSent = true;
          this.loader = null;
          this.loading = false;
        })
        .catch((error) => {
          // An error happened.
          console.log(error);
          this.alert = true;
          this.alertMessage = error.message;
          this.loader = null;
          this.loading = false;
        });
    },
    mailToOB() {
      window.open("mailto:yo@charged.asia", "_blank");
    },
    enterPressed(e) {
      if (e.keyCode === 13) {
        // alert('Enter was pressed')
        this.signIn();
      }
    },
    change1() {
      this.alert = false;
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      // setTimeout(() => (this[l] = false), 3000)
    },
  },
};
</script>
<style>
.myDiv {
  background: url("/img/app/card_bg.jpg") no-repeat;
}
</style>
